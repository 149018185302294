<template>
  <div class="container-fluid">
    <router-link
      :to="{ name: $route.query.parents_url }"
      v-slot="{ href, route, navigate }"
      custom
    >
      <a :href="href" @click="navigate" class="mr-2"
        ><i class="fa fa-chevron-left mr-1"></i>Back</a
      >
    </router-link>
    <div class="d-flex justify-content-between mt-2">
      <b-row>
        <div class="d-flex justify-content-between ml-3">
          <h6>Visit #{{ this.visit_data?.visit_code }}</h6>
          <div class="ml-1">
            <b-button
              size="sm"
              @click="handleQRPreview"
              style="font-size: var(--font-size-normal)"
              v-b-tooltip.hover
              title="Visitation Code QR"
            >
              <i class="fas fa-qrcode"></i>
            </b-button>
          </div>
        </div>
      </b-row>

      <b-row>
        <b-button
          size="sm"
          @click="handleBadgePdfPreview"
          style="font-size: var(--font-size-normal)"
          :disabled="disabled('badge pdf')"
          v-b-tooltip.hover
          title="Visit Badge"
        >
          <i class="fas fa-id-badge"></i>
        </b-button>

        <b-button
          size="sm"
          class="ml-1"
          @click="handlePdfPreview(null)"
          style="font-size: var(--font-size-normal)"
          v-b-tooltip.hover
          title="Visit Receipt"
        >
          <i class="fas fa-file-invoice"></i>
        </b-button>

        <b-button
          size="sm"
          variant="primary"
          class="ml-1 mr-3"
          style="font-size: var(--font-size-normal)"
          @click="showEmailChecklistModal"
          v-b-tooltip.hover
          title="Visit Notifications"
        >
          <i class="far fa-bell"></i>
        </b-button>
      </b-row>
    </div>

    <b-card class="bg-surface-2 mt-3">
      <div class="d-flex justify-content-between pt-2 ml-2">
        <b-row no-gutters>
          <strong>Visit Access</strong>
        </b-row>

        <div class="d-flex justify-content-end ml-5">
          <b-row no-gutters>
            <b-button
              variant="primary"
              @click="showAddAccessForm"
              size="sm"
              style="font-size: var(--font-size-normal)"
              :disabled="disabled('generate access')"
            >
              Generate Access
            </b-button>
            <b-button
              class="pr-3 mr-1"
              variant="danger"
              @click="triggerDeleteAllAccess"
              size="sm"
              style="font-size: var(--font-size-normal)"
              :disabled="disabled('revoke access')"
            >
              <span class="ml-2">Revoke access</span>
            </b-button>

            <b-button
              v-if="isEditAccessGroups"
              v-b-tooltip.hover
              title="Confirm Visit Details"
              variant="success"
              size="sm"
              @click="onSubmitUpdateVisitDetailsForm"
              :disabled="disabled('confirm')"
            >
              <i class="far fa-check-circle"></i>
            </b-button>
            <b-button
              v-if="!isEditAccessGroups && $route.query.parents_url != 'kiosk2'"
              v-b-tooltip.hover
              title="Update Visit Details"
              variant="secondary"
              size="sm"
              @click="showUpdateVisitDetailsForm"
              :disabled="disabled('update')"
            >
              <i class="fas fa-edit"></i>
            </b-button>
          </b-row>
        </div>
      </div>

      <div class="d-flex justify-content-between pt-2">
        <b-card-group class="w-100 my-2">
          <b-card
            class="d-flex justify-content-between bg-surface-2 border-0"
            v-if="getMasterAccessMetadata != null"
          >
            <b-row
              class="d-flex justify-content-start"
              v-if="getMasterAccessMetadata.access_types.length !== 0"
            >
              <b-col lg="2" v-if="containQrAccess(getMasterAccessMetadata)">
                <b-card class="text-center bg-surface-1 pb-4">
                  <div class="d-flex justify-content-between">
                    <strong> Visit Access QR Code</strong>
                    <div>
                      <b-button
                        variant="primary"
                        style="font-size: var(--font-size-normal)"
                        @click="
                          triggerSendNotification(
                            'visit_access',
                            ['qr'],
                            'dynamic'
                          )
                        "
                      >
                        <i class="far fa-envelope"></i>
                      </b-button>
                    </div>
                  </div>

                  <div class="visitor-card-face-image mx-auto d-block">
                    <img
                      :src="
                        showImage(
                          accessQrContentFormatter(
                            getMasterAccessMetadata?.qr_string
                          )
                        )
                      "
                      fluid
                    />
                  </div>
                </b-card>
              </b-col>
              <b-col lg="2" v-if="containCardAccess(getMasterAccessMetadata)">
                <b-card class="text-center bg-surface-1">
                  <div class="d-flex justify-content-between mb-2">
                    <strong>Physical Card</strong>
                    <div>
                      <b-button
                        variant="primary"
                        style="font-size: var(--font-size-normal)"
                        @click="
                          triggerSendNotification('visit_access', ['card'])
                        "
                      >
                        <i class="far fa-envelope"></i>
                      </b-button>
                    </div>
                  </div>

                  <div
                    class="mx-auto d-block d-flex flex-column align-items-start border rounded"
                    style="max-width: 200px; height: 120px"
                  >
                    <div class="d-flex mb-auto pt-3">
                      <h5>
                        <strong class="physical-card">
                          {{ getMasterAccessMetadata?.access_card_no }}
                        </strong>
                      </h5>
                    </div>
                    <div class="d-flex w-100 justify-content-end">
                      <div class="d-flex">
                        <div>
                          <i class="fa fa-info-circle fa-lg"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card>
              </b-col>
              <b-col lg="2" v-if="containFaceAccess(getMasterAccessMetadata)">
                <b-card class="text-center bg-surface-1 pb-4">
                  <div class="d-flex justify-content-between mb-1">
                    <strong>Face</strong>
                    <div>
                      <b-button
                        variant="primary"
                        style="font-size: var(--font-size-normal)"
                        @click="
                          triggerSendNotification('visit_access', ['face'])
                        "
                      >
                        <i class="far fa-envelope"></i>
                      </b-button>
                    </div>
                  </div>

                  <div class="visitor-card-face-image mx-auto d-block">
                    <img :src="showImage(getMasterVisitor.face_image)" fluid />
                  </div>
                </b-card>
              </b-col>
              <b-col
                lg="2"
                v-if="containCarPlateNumberAccess(getMasterAccessMetadata)"
              >
                <b-card class="text-center bg-surface-1 pb-4">
                  <div class="d-flex justify-content-between mb-1">
                    <strong>License Plate Number</strong>
                    <div>
                      <b-button
                        variant="primary"
                        style="font-size: var(--font-size-normal)"
                        @click="
                          triggerSendNotification('visit_access', [
                            'car_plate_no'
                          ])
                        "
                      >
                        <i class="far fa-envelope"></i>
                      </b-button>
                    </div>
                  </div>

                  <div>
                    <i class="fas fa-car" style="font-size: 70px"></i>
                    <div
                      class="mx-auto d-block d-flex flex-column border rounded"
                      style="max-width: 150px; height: 30px"
                    >
                      <div class="d-flex justify-content-center mb-auto">
                        <h5>
                          <strong>
                            {{ getMasterAccessMetadata.car_plate_no }}
                          </strong>
                        </h5>
                      </div>
                    </div>
                  </div>
                </b-card>
              </b-col>
              <b-col
                lg="2"
                v-if="containFingerprintAccess(getMasterAccessMetadata)"
              >
                <b-card class="text-center bg-surface-1 pb-4">
                  <div class="d-flex justify-content-between mb-1">
                    <strong>Fingerprint</strong>
                    <div>
                      <b-button
                        variant="primary"
                        style="font-size: var(--font-size-normal)"
                        @click="
                          triggerSendNotification('visit_access', [
                            'fingerprint'
                          ])
                        "
                      >
                        <i class="far fa-envelope"></i>
                      </b-button>
                    </div>
                  </div>

                  <div>
                    <i class="fas fa-fingerprint" style="font-size: 100px"></i>
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-card v-else class="bg-surface-2">
              <b-card-text class="text-center"
                >-- No access is assigned yet --
              </b-card-text>
            </b-card>
          </b-card>
          <b-card v-else class="bg-surface-2">
            <b-card-text class="text-center"
              >-- No access is assigned yet --
            </b-card-text>
          </b-card>
        </b-card-group>
      </div>
    </b-card>

    <b-card class="bg-surface-2 mt-3 mb-5">
      <b-card-title style="font-size: var(--font-size-normal)">
        <strong>Visit Details</strong>
      </b-card-title>
      <div class="d-flex">
        <b-card-group class="w-100">
          <b-card class="bg-surface-2 w-100">
            <b-card-text
              >Visit Date : {{ this.visit_data.visit_start_date | date }} -
              {{ this.visit_data.visit_end_date | date }}
            </b-card-text>
            <b-card-text
              >Visit Time :
              {{ this.visit_data.visit_start_time | unixToTime12Hours }} -
              {{ this.visit_data.visit_end_time | unixToTime12Hours }}
            </b-card-text>
            <b-card-text
              >Reason :
              {{
                this.visit_data.visit_purpose != null
                  ? this.visit_data.visit_purpose
                  : this.visit_data.remark | humanizeText | dashForEmptyText
              }}
            </b-card-text>
            <b-card-text
              ><div class="visitor-card-face-image d-block">
                <img
                  v-if="checkPhoto"
                  :src="showImage(getMasterVisitor?.face_image)"
                  fluid
                />
                <img v-else :src="showImage(formAdd.face_image)" fluid />
              </div>
              <div v-if="isEditAccessGroups">
                <b-button
                  class="ml-1 mr-1"
                  size="sm"
                  variant="warning"
                  v-b-tooltip.hover
                  title="Take Photo"
                  @click="editProfilePicture"
                >
                  <i class="fas fa-camera"></i>
                </b-button>
              </div>
            </b-card-text>
          </b-card>
          <b-card class="bg-surface-2 w-100">
            <b-card-title style="font-size: var(--font-size-normal)"
              ><strong>Host</strong></b-card-title
            >
            <b-card-text
              >Name :
              {{ getMasterHost?.name | humanizeText | dashForEmptyText }}
            </b-card-text>
            <b-card-text
              >Phone :
              {{ getMasterHost?.contact_number | dashForEmptyText }}
            </b-card-text>
            <b-card-text
              >Email : {{ getMasterHost?.email | dashForEmptyText }}
            </b-card-text>
            <b-card-text
              >Company :
              {{ company?.name | humanizeText | dashForEmptyText }}
            </b-card-text>
            <b-card-text v-if="isEditAccessGroups">
              Access Group :
              <span>
                <multiselect
                  placeholder="Choose access group"
                  v-model="formUpdate.access_metadata.access_groups"
                  label="access_group"
                  track-by="access_group"
                  :options="options_access_groups"
                  :multiple="true"
                  :allow-empty="false"
                  :max-height="500"
                ></multiselect
              ></span>
            </b-card-text>
            <b-card-text v-else
              >Access Group :
              <span v-if="getMasterAccessMetadata?.access_groups">
                {{ handleAccessGroups("visitAccess") }}
              </span>
              <span v-else-if="company?.access_metadata?.access_groups">
                {{ handleAccessGroups("company") }}
              </span>
              <span v-else>
                {{ handleAccessGroups("setting") }}
              </span>
            </b-card-text>
            <b-card-text v-if="liftAccessLevel()">
              Lift Access Level :
              <span v-if="isEditLiftAccessLevel">
                <multiselect
                  placeholder="Choose lift access level"
                  v-model="formUpdate.access_metadata.lift_access_level"
                  label="description"
                  track-by="lift_access_level"
                  :custom-label="customLabel"
                  :options="options_lift_access_levels"
                  :multiple="false"
                  :allow-empty="false"
                  :max-height="500"
                ></multiselect>
              </span>
              <span
                v-else-if="
                  this.company?.access_metadata?.lift_access_level
                    .lift_access_level ==
                  this.getMasterAccessMetadata?.lift_access_level
                "
              >
                {{
                  `${this.company?.access_metadata?.lift_access_level?.lift_access_level} - ${this.company?.access_metadata?.lift_access_level?.description}`
                }}
              </span>
              <span v-else>
                {{ this.lift_access_level }}
              </span>
            </b-card-text>
          </b-card>
          <b-card class="bg-surface-2 w-100">
            <b-card-title style="font-size: var(--font-size-normal)"
              ><strong>Visitor</strong></b-card-title
            >
            <b-card-text
              >Name :
              {{ getMasterVisitor?.name | humanizeText | dashForEmptyText }}
            </b-card-text>
            <b-card-text
              >Phone :
              {{ getMasterVisitor?.contact_number | dashForEmptyText }}
            </b-card-text>
            <b-card-text
              >Email :
              {{ getMasterVisitor?.email | dashForEmptyText }}
            </b-card-text>
            <b-card-text
              >Company :
              {{
                getMasterVisitor?.additional_metadata.company != null
                  ? getMasterVisitor?.additional_metadata.company
                  : getMasterVisitor?.profile.company | dashForEmptyText
              }}
            </b-card-text>
            <b-card-text v-if="getMasterVisitor?.nric_number != null"
              >ID (NRID) :
              {{ getMasterVisitor?.nric_number | dashForEmptyText }}
            </b-card-text>
            <b-card-text v-if="getMasterVisitor?.passport_number != null"
              >ID (Passport Number) :
              {{ getMasterVisitor?.passport_number | dashForEmptyText }}
            </b-card-text>
            <b-card-text v-if="getMasterVisitor?.profile.gender != null"
              >Gender :
              {{
                getMasterVisitor?.profile.gender
                  | dashForEmptyText
                  | humanizeText
              }}
            </b-card-text>
          </b-card>
        </b-card-group>
      </div>
    </b-card>

    <!-- add access card -->
    <b-modal
      id="addAccessForm"
      title="Add Access"
      ref="addAccessForm"
      centered
      hide-footer
      @close="close()"
      :no-close-on-backdrop="true"
    >
      <b-form @submit="onSubmitAddAccessForm">
        <b-form-group label="Access Group">
          <span v-if="getMasterAccessMetadata?.access_groups">
            {{ handleAccessGroups("visitAccess") }}
          </span>
          <span v-else-if="company?.access_metadata?.access_groups">
            {{ handleAccessGroups("company") }}
          </span>
          <span v-else>
            {{ handleAccessGroups("setting") }}
          </span>
        </b-form-group>

        <b-form-group v-if="company?.access_metadata" label="Access Level">
          <span v-if="company?.access_metadata?.access_level">{{
            company?.access_metadata?.access_level?.access_level
          }}</span>
          <span v-else>{{
            getPreference?.additional_metadata?.vms_preferences_access_level
              ?.access_level
          }}</span>
        </b-form-group>

        <b-form-group v-else label="Access Level">
          <span>{{
            getPreference?.additional_metadata?.vms_preferences_access_level
              ?.access_level
          }}</span>
        </b-form-group>

        <b-form-group label="Access Type">
          <b-badge v-if="this.accessTypes('card')"
            ><strong style="font-size: var(--font-size-normal)"
              >Card</strong
            ></b-badge
          >
          <b-badge v-if="this.accessTypes('dynamic qr')"
            ><strong style="font-size: var(--font-size-normal)"
              >QR (Dynamic)</strong
            ></b-badge
          >
          <b-badge v-if="this.accessTypes('static qr')"
            ><strong style="font-size: var(--font-size-normal)"
              >QR (Static)</strong
            ></b-badge
          >
          <b-badge v-if="this.accessTypes('face')"
            ><strong style="font-size: var(--font-size-normal)"
              >Face</strong
            ></b-badge
          >
          <b-badge v-if="this.accessTypes('car_plate_no')"
            ><strong style="font-size: var(--font-size-normal)"
              >Car Plate No</strong
            ></b-badge
          >
          <b-badge v-if="this.accessTypes('fingerprint')"
            ><strong style="font-size: var(--font-size-normal)"
              >Fingerprint</strong
            ></b-badge
          >
          <b-badge v-if="this.accessTypes('lift_card')"
            ><strong style="font-size: var(--font-size-normal)"
              >Lift Card</strong
            ></b-badge
          >
        </b-form-group>

        <div v-if="getPreference.additional_metadata != undefined">
          <b-form-group label="Access Card No" v-if="this.accessTypes('card')">
            <b-input-group>
              <b-form-input
                v-model="form.access_card_no"
                required
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </div>

        <div class="text-danger" v-if="formErrorMessage">
          {{ formErrorMessage }}
        </div>

        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>
    <!-- update visitor image-->
    <b-modal
      id="form-add-visitor-image"
      ref="formAddVisitorImage"
      title="Add Visitor Face"
      centered
      hide-footer
      :no-close-on-backdrop="true"
      :hide-header-close="false"
      @close="stopCamera()"
    >
      <b-form>
        <!-- capture image -->
        <div
          class="row justify-content-center mt-2"
          v-show="capturedPhoto != null"
        >
          <div>
            <img :src="showImage(formAdd.face_image)" fluid />
          </div>
        </div>

        <div
          class="row justify-content-center mt-2"
          v-show="showTakePhoto == true"
        >
          <div
            id="live_video_container"
            ref="cameraView"
            v-show="capturedPhoto == null"
          ></div>
          <div
            id="photo_container"
            ref="photoView"
            v-show="capturedPhoto != null"
          ></div>
        </div>

        <div
          class="row justify-content-center mt-2"
          v-show="showUploadPhoto == true"
        >
          <div class="visitor-card-face-image">
            <img :src="showImage(formAdd.face_image)" fluid />
          </div>
        </div>

        <div
          class="row justify-content-center mt-2"
          v-if="showTakePhoto == true"
        >
          *Note : Please uncover and position your face in front of the camera.
        </div>
        <!-- take photo -->
        <div class="d-flex justify-content-center" v-if="showTakePhoto">
          <!-- <b-button class="my-2 mx-1">Upload File</b-button> -->
          <b-button
            v-if="capturedPhoto != null"
            class="my-2 mx-1"
            @click="retakePhoto"
            >Retake Photo
          </b-button>
          <b-button
            v-if="capturedPhoto == null"
            class="my-2 mx-1"
            variant="primary"
            @click="takePhoto"
            >Take Photo
          </b-button>
          <b-button
            v-if="capturedPhoto == null"
            class="my-2 mx-1"
            size="md"
            variant="warning"
            v-b-tooltip.hover
            @click="getWebCamDeviceList"
          >
            <i class="fa-solid fa-camera-rotate"></i>
          </b-button>
        </div>
        <div class="mt-4 d-flex justify-content-end">
          <b-button
            variant="primary"
            :disabled="capturedPhoto == null"
            @click="closeTakePictureModal"
            >OK
          </b-button>
        </div>
        <div class="mt-4 d-flex justify-content-end"></div>
      </b-form>
    </b-modal>
    <!-- email -->
    <b-modal
      id="emailChecklistModal"
      title="Notifications"
      ref="showEmail"
      :hide-footer="true"
      centered
      :scrollable="true"
    >
      <div>
        <b-table-simple div responsive size="small">
          <b-tbody>
            <b-tr
              v-for="(value, propertyName) in getMasterNotificationsMetadata"
              :key="propertyName"
            >
              <b-td>{{ propertyName | humanizeText }}</b-td>
              <b-td>
                <i v-if="value" class="fas fa-check"></i>
                <i v-else class="fas fa-times"></i>
              </b-td>
              <b-td>
                <div
                  style="cursor: pointer"
                  @click="triggerSendNotification(propertyName)"
                >
                  <u>Resend</u>
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
      <div>
        <div class="text-right">
          <b-button variant="primary" @click="closeModalEmail">OK</b-button>
        </div>
      </div>
    </b-modal>
    <!-- pdf visit receipt and print -->
    <b-modal
      title="Preview Visit Receipt"
      ref="previewPdf"
      :hide-footer="true"
      centered
      :scrollable="true"
    >
      <br />

      <div class="text-black p-3 border" ref="previewReceiptHtml">
        <div class="row" v-if="this.isFace == null">
          <div class="col">
            <h1><strong>Visit Details</strong></h1>
            <table>
              <tr>
                {{
                  this.visit_data.visit_start_date | date
                }}
                -
                {{
                  this.visit_data.visit_end_date | date
                }}
              </tr>
              <tr>
                {{
                  this.visit_data.visit_start_time | unixToTime12Hours
                }}
                -
                {{
                  this.visit_data.visit_end_time | unixToTime12Hours
                }}
              </tr>
              <tr>
                {{
                  this.visit_data.visit_purpose != null
                    ? this.visit_data.visit_purpose
                    : this.visit_data.remark | humanizeText | dashForEmptyText
                }}
              </tr>
            </table>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <h1><strong>Visitor</strong></h1>
            <table>
              <tr>
                {{
                  getMasterVisitor.name | humanizeText
                }}
              </tr>
              <tr>
                {{
                  getMasterVisitor.nric_number
                }}
              </tr>
              <tr>
                {{
                  getMasterVisitor.email
                }}
              </tr>
              <tr>
                {{
                  getMasterVisitor.contact_number
                }}
              </tr>
            </table>
          </div>
          <div class="col">
            <table>
              <tr class="visitor-image">
                <img :src="showImage(getMasterVisitor.face_image)" fluid />
              </tr>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <table>
              <tr>
                Visitation Code
                <td>:</td>
                <td>#{{ visit_data.visit_code }}</td>
              </tr>
              <tr>
                Host company
                <td>:</td>
                <td>{{ company.name | humanizeText }}</td>
              </tr>
              <tr>
                Host name
                <td>:</td>
                <td>{{ getMasterHost.name | humanizeText }}</td>
              </tr>
              <tr>
                Host phone
                <td>:</td>
                <td>{{ getMasterHost.contact_number }}</td>
              </tr>
              <tr>
                Host email
                <td>:</td>
                <td>{{ getMasterHost.email }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <b-button variant="primary" class="mr-1" @click="printPdf"
          >Print
        </b-button>
        <b-button variant="secondary" @click="downloadPdf">Download</b-button>
      </div>
    </b-modal>
    <!-- pdf visit badge and print -->
    <b-modal
      title="Visit Badge"
      ref="previewVisitBadgePdf"
      :hide-footer="true"
      centered
      :scrollable="true"
    >
      <br />

      <div class="bg-white text-black p-3" ref="previewReceiptHtml">
        <b-card class="border">
          <b-row>
            <b-col>
              <strong class="badge-font">Visitor</strong>
              <div>
                {{ getMasterVisitor.name | humanizeText }}
                <br />
                {{ getMasterVisitor.contact_number }}
              </div>
              <br />
              <div><strong>Visitation Code</strong><span>:</span></div>
              {{ visit_data.visit_code }}
              <div><strong>Host name</strong><span>:</span></div>
              {{ getMasterHost.name | humanizeText }}
              <div><strong>Host company</strong><span>:</span></div>
              {{ company.name | humanizeText }}
            </b-col>
            <b-col>
              <div class="visitor-image" style="cursor: pointer">
                <img :src="showImage(getMasterVisitor.face_image)" fluid />
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <div class="mt-3">
        <b-button variant="primary" class="mr-1" @click="printPdf"
          >Print
        </b-button>
        <b-button variant="secondary" @click="downloadPdf">Download</b-button>
      </div>
    </b-modal>
    <!-- pdf and print -->
    <b-modal
      title="Preview Visitation Code QR"
      ref="previewQR"
      :hide-footer="true"
      centered
      :scrollable="true"
    >
      <br />

      <div class="bg-white text-black text-center border" ref="previewQRHtml">
        <div class="visitationQRFont">Visitation Code QR</div>
        <div v-if="inviteQR != null">
          <img :src="inviteQR" fluid />
        </div>
        <div v-else>
          <img :src="visitQR" fluid />
        </div>
      </div>
      <div class="d-flex justify-content-start mt-3">
        <b-button variant="primary" class="mr-1" @click="printPdf"
          >Print
        </b-button>
        <b-button variant="secondary" @click="downloadPdf">Download</b-button>
      </div>
    </b-modal>

    <!-- error message modal -->
    <!-- error message operator modal -->
    <b-modal
      id="form-error-message-car-plate-no-operator"
      title="Error Message"
      centered
      ok-only
      :no-close-on-backdrop="true"
      :hide-header-close="false"
      v-if="getMasterVisitor != null"
    >
      <table>
        <tr>
          <td>
            <h1><i class="fa fa-exclamation-circle" style="color: red"></i></h1>
          </td>
          <td>
            &nbsp; {{ getMasterVisitor.name | humanizeText }} 's car plate
            number is empty.
          </td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
          <td>
            You can click on this
            <router-link
              :to="{
                name: 'visitors',
                query: {
                  keyword: getMasterVisitor?.name
                }
              }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <a :href="href" @click="navigate"
                ><span style="color: blue"><u>link</u></span></a
              >
            </router-link>
            or go to Manage Visitors to update it.
          </td>
        </tr>
      </table>
    </b-modal>

    <b-modal
      id="form-error-message-fingerprint-operator"
      title="Error Message"
      centered
      ok-only
      :no-close-on-backdrop="true"
      :hide-header-close="false"
      v-if="getMasterVisitor != null"
    >
      <table>
        <tr>
          <td>
            <h1><i class="fa fa-exclamation-circle" style="color: red"></i></h1>
          </td>
          <td>
            &nbsp; {{ getMasterVisitor.name | humanizeText }} 's fingerprint is
            empty.
          </td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
          <td>
            You can click on this
            <router-link
              :to="{
                name: 'visitors',
                query: {
                  keyword: getMasterVisitor?.name
                }
              }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <a :href="href" @click="navigate"
                ><span style="color: blue"><u>link</u></span></a
              >
            </router-link>
            or go to Manage Visitors to update it.
          </td>
        </tr>
      </table>
    </b-modal>

    <!-- error message-kiosk modal -->
    <b-modal
      id="form-error-message-car-plate-no-kiosk"
      title="Error Message"
      centered
      ok-only
      :no-close-on-backdrop="true"
      :hide-header-close="false"
      v-if="getMasterVisitor != null"
    >
      <table>
        <tr>
          <td>
            <h1><i class="fa fa-exclamation-circle" style="color: red"></i></h1>
          </td>
          <td>&nbsp; Our system has been updated.</td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
          <td>Please register as a new visitor.</td>
        </tr>
      </table>
    </b-modal>

    <b-modal
      id="form-error-message-fingerprint-kiosk"
      title="Error Message"
      centered
      ok-only
      :no-close-on-backdrop="true"
      :hide-header-close="false"
      v-if="getMasterVisitor != null"
    >
      <table>
        <tr>
          <td>
            <h1><i class="fa fa-exclamation-circle" style="color: red"></i></h1>
          </td>
          <td>&nbsp; Our system has been updated.</td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
          <td>Please register as a new visitor.</td>
        </tr>
      </table>
    </b-modal>

    <!-- error message-both-operator modal -->
    <b-modal
      id="form-error-message-both-operator"
      title="Error Message"
      centered
      ok-only
      :no-close-on-backdrop="true"
      :hide-header-close="false"
      v-if="getMasterVisitor != null"
    >
      <table>
        <tr>
          <td>
            <h1><i class="fa fa-exclamation-circle" style="color: red"></i></h1>
          </td>
          <td>
            &nbsp; {{ getMasterVisitor.name | humanizeText }} 's car plate
            number & fingerprint is empty.
          </td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
          <td>
            You can click on this
            <router-link
              :to="{
                name: 'visitors',
                query: {
                  keyword: getMasterVisitor?.name
                }
              }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <a :href="href" @click="navigate"
                ><span style="color: blue"><u>link</u></span></a
              >
            </router-link>
            or go to Manage Visitors to update it.
          </td>
        </tr>
      </table>
    </b-modal>

    <!-- error message-both-kiosk modal -->
    <b-modal
      id="form-error-message-both-kiosk"
      title="Error Message"
      centered
      ok-only
      :no-close-on-backdrop="true"
      :hide-header-close="false"
      v-if="getMasterVisitor != null"
    >
      <table>
        <tr>
          <td>
            <h1><i class="fa fa-exclamation-circle" style="color: red"></i></h1>
          </td>
          <td>&nbsp; Our system has been updated.</td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
          <td>Please register as a new visitor.</td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import moment from "moment";
import jQuery from "jquery";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import * as imageConversion from "image-conversion";

let $ = (jQuery = require("jquery"));
$ = $.extend(require("webpack-jquery-ui"));
$ = $.extend(require("webpack-jquery-ui/css"));
$ = $.extend(require("formBuilder"));
$ = $.extend(require("formBuilder/dist/form-render.min.js"));

let convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

export default {
  components: {
    Multiselect
  },
  data: function () {
    return {
      visitId: null,
      visit_data: {
        id: null,
        status: null,
        created: null,
        modified: null,
        visitor_type: null,
        visit_purpose: null,
        visit_end_date: null,
        visit_end_time: null,
        visit_start_date: null,
        visit_start_time: null,
        registration_type: null,
        tolerance_time_in: null,
        tolerance_time_out: null,
        badges: {},
        vehicles: {},
        visit_days: {},
        access_metadata: {},
        visit_extensions: {},
        additional_metadata: {}
      },
      hosts: [],
      company: {},
      visitors: [],

      acsUrls: [],
      settings: {},
      all_access_levels: {},

      summaryView: false,
      isEditAccessGroups: false,
      isEditLiftAccessLevel: false,
      isFace: null,
      access: null,
      intervalid: null,
      invite_code: null,
      redirectTimeout: null,
      formErrorMessage: null,

      form: {
        access_types: [],
        access_groups: [],
        access_level: null,
        access_card_no: null,
        selected_acs_url: null,
        qr_code_type: null
      },
      formAdd: {
        face_image: null
      },
      formUpdate: {
        access_metadata: {
          access_level: [],
          access_groups: [],
          lift_access_level: {}
        }
      },
      showTakePhoto: false,
      showUploadPhoto: false,

      camera: null,
      uploadedPhoto: null,
      capturedPhoto: null,

      countTrigger: 0,
      countdownCounter: 180,

      invites: [],
      inviteQR: null,
      visitQR: null,

      options_access_groups: [],
      cameraType: null,
      lift_access_level: null,

      defaultImage: require("@/assets/global/images/icons/profile_sample.jpg")
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL",
      getPreference: "vmsPreferences2Module/getPreferences",
      getSettings: "vmsSettingsModule/getSettings"
    }),
    getMasterHost() {
      let result = null;
      let output = {
        is_active: true,
        id: null,
        name: null,
        email: null,
        created: null,
        modified: null,
        company_id: null,
        contact_number: null,
        profile: {},
        additional_metadata: {}
      };

      this.hosts.length > 0
        ? (result = {
            ...output,
            ...this.hosts[0]
          })
        : (result = {
            ...output
          });
      return result;
    },
    getMasterVisitor() {
      let result = null;
      let output = {
        is_blocklisted: false,
        id: null,
        name: null,
        email: null,
        created: null,
        modified: null,
        face_image: null,
        nric_number: null,
        contact_number: null,
        passport_number: null,
        profile: {},
        additional_metadata: {}
      };

      this.visitors.length > 0
        ? (result = {
            ...output,
            ...this.visitors[0]
          })
        : (result = {
            ...output
          });
      return result;
    },
    checkPhoto() {
      if (this.formAdd.face_image == null) {
        return true;
      } else {
        return false;
      }
    },
    getMasterAccessMetadata() {
      let access =
        this.visit_data?.access_metadata?.visitors?.[
          this.getMasterVisitor.id
        ] ?? [];

      return access?.length > 0 ? access[0] : null;
    },
    getMasterNotificationsMetadata() {
      let visitorId = this.getMasterVisitor?.id ?? null;
      let notifications =
        this.visit_data?.additional_metadata?.notifications ?? null;
      let meta = {
        visit_access: false,
        visit_registration: false
      };
      let result =
        visitorId != null && notifications != null
          ? {
              ...meta,
              ...notifications[visitorId]
            }
          : meta;

      return result;
    }
  },
  watch: {
    $route: function () {
      this.summaryView = this.$route.query.summary_view
        ? this.$route.query.summary_view === "true"
        : undefined;
      this.$route.query.parents_url == "kiosk2" ? this.timer() : null;
      this.visitId = this.$route?.params?.id
        ? this.$route.params.id
        : undefined;
      this.visitId != null ? this.init() : null;
    }
  },
  async mounted() {
    this.summaryView = this.$route.query.summary_view
      ? this.$route.query.summary_view === "true"
      : undefined;
    this.$route.query.parents_url == "kiosk2" ? this.timer() : null;
    this.visitId = this.$route?.params?.id ? this.$route.params.id : undefined;
    this.visitId != null ? this.init() : null;

    await this.$store.dispatch("vmsPreferences2Module/getAllPreferences");
    await this.$store.dispatch("vmsSettingsModule/getSettings");
  },
  methods: {
    closeTakePictureModal() {
      this.$bvModal.hide("form-add-visitor-image");
      this.stopCamera();
    },
    editProfilePicture() {
      this.getWebCamDeviceList();
      this.$bvModal.show("form-add-visitor-image");
    },
    async init() {
      try {
        this.visit_data = await this.fetchVisit(this.visitId);
        console.log("visit_data", this.visit_data);
        await this.fetchVisitQR(this.visit_data);
      } catch (e) {
        console.log(e);
      }

      try {
        this.visitors = await this.fetchVisitors(this.visit_data);
        console.log("visitors", this.visitors);
      } catch (e) {
        console.log(e);
      }

      try {
        this.hosts = await this.fetchHosts(this.visit_data);
        console.log("hosts", this.hosts);

        for (let i = 0; i < this.hosts.length; i++) {
          this.company = await this.fetchCompanys(this.hosts[i].company_id);
          console.log("company", this.company);
        }
      } catch (e) {
        console.log(e);
      }

      try {
        this.invites = await this.fetchInvites(this.visit_data);
        console.log("invites", this.invites);

        if (this.invites.length != 0) {
          await this.fetchInviteQR(this.invites[0]);
        }
      } catch (e) {
        console.log(e);
      }

      try {
        this.acsUrls = await this.fetchP1Members();
        this.form.selected_acs_url = this.acsUrls[0];
        console.log("acsUrls", this.acsUrls);
        for (let i = 0; i < this.acsUrls.length; i++) {
          this.options_access_groups = await this.fetchAccessGroup(
            this.acsUrls[i]
          );
          this.all_access_levels = await this.fetchAccessLevel(this.acsUrls[i]);
          this.options_lift_access_levels = await this.fetchLiftAccessLevel(
            this.acsUrls[i]
          );
        }
        this.changeLabelLiftAccess();
      } catch (e) {
        console.log(e);
      }
    },
    async fetchVisit(id) {
      let API_URL =
        this.getAPIServerURL + "/api/visitorservice/visit/?visit_id=" + id;
      const client = this.$root.getAjaxFetchClient();
      let res = await client.getRequest(API_URL);
      let validUrl = null;
      outer_loop: for (let key in res?.result) {
        validUrl = key;
        break outer_loop;
      }
      return res?.result?.[validUrl] ?? {};
    },
    async fetchVisitQR(visit_data) {
      let API_URL = this.getAPIServerURL + "/api/visitorservice/visit/qr/";
      const client = this.$root.getAjaxFetchClient({ responseType: "blob" });
      if (visit_data != undefined) {
        let response = await client.getRequest(API_URL, {
          visit_id: visit_data?.id
        });
        let reader = new window.FileReader();
        reader.readAsDataURL(response);
        reader.onload = () => {
          this.visitQR = reader.result;
          console.log("this.visitQR", this.visitQR);
        };
      } else {
        this.visitQR = null;
      }
    },
    async fetchVisitors(visit_data) {
      let $this = this;
      let API_URL =
        this.getAPIServerURL +
        "/api/visitorservice/visit/" +
        visit_data.id +
        "/visitors/";

      const client = this.$root.getAjaxFetchClient();
      let res = await client.getRequest(API_URL);
      let validUrl = null;
      outer_loop: for (let key in res?.result) {
        validUrl = key;
        break outer_loop;
      }

      for (let key2 in res?.result?.[validUrl]) {
        if (
          this.getPreference?.additional_metadata?.vms_pdpa_setting ==
          "save_partial"
        ) {
          if (res.result[validUrl][key2].passport_number != null) {
            res.result[validUrl][key2].passport_number = $this.getPartial(
              res?.result?.[validUrl]?.[key2]?.passport_number
            );
          } else {
            res.result[validUrl][key2].nric_number = $this.getPartial(
              res?.result?.[validUrl]?.[key2]?.nric_number
            );
          }

          res.result[validUrl][key2].custom_id = $this.getCustomId(
            res?.result?.[validUrl]?.[key2]
          );
        }
      }

      return res?.result?.[validUrl] ?? [];
    },
    async fetchHosts(visit_data) {
      let API_URL =
        this.getAPIServerURL +
        "/api/visitorservice/visit/" +
        visit_data.id +
        "/hosts/";
      const client = this.$root.getAjaxFetchClient();
      let res = await client.getRequest(API_URL);
      let validUrl = null;
      outer_loop: for (let key in res?.result) {
        validUrl = key;
        break outer_loop;
      }
      return res?.result?.[validUrl] ?? [];
    },
    async fetchCompanys(company_id) {
      let API_URL =
        this.getAPIServerURL +
        "/api/visitorservice/company/?company_id=" +
        company_id;
      const client = this.$root.getAjaxFetchClient();
      let res = await client.getRequest(API_URL);
      let validUrl = null;
      outer_loop: for (let key in res?.result) {
        validUrl = key;
        break outer_loop;
      }
      return res?.result?.[validUrl] ?? [];
    },
    async fetchInvites(visit_data) {
      let API_URL = this.getAPIServerURL + "/api/visitorservice/invite/all/";
      const client = this.$root.getAjaxFetchClient();
      let res = await client.getRequest(API_URL, { visit_id: visit_data.id });
      let validUrl = null;
      outer_loop: for (let key in res?.result) {
        validUrl = key;
        break outer_loop;
      }
      return res?.result?.[validUrl] ?? [];
    },
    async fetchInviteQR(invite) {
      let API_URL = this.getAPIServerURL + "/api/visitorservice/invite/qr/";
      const client = this.$root.getAjaxFetchClient({ responseType: "blob" });
      if (invite != undefined) {
        let response = await client.getRequest(API_URL, {
          invite_id: invite?.id
        });
        let reader = new window.FileReader();
        reader.readAsDataURL(response);
        reader.onload = () => {
          this.inviteQR = reader.result;
          console.log("this.inviteQR", this.inviteQR);
        };
      } else {
        this.inviteQR = null;
      }
    },
    async fetchP1Members() {
      let API_URL =
        this.getAPIServerURL + "/api/monitorservice/members/?group=EP_P1";
      const client = this.$root.getAjaxFetchClient();
      let res = await client.getRequest(API_URL);
      let validUrl = null;
      outer_loop: for (let key in res?.result) {
        validUrl = key;
        break outer_loop;
      }
      return res?.result?.[validUrl]?.result ?? [];
    },
    async fetchAccessGroup(acs_url) {
      let API_URL =
        this.getAPIServerURL +
        "/api/acsservice/info/access-groups/?acs_url=" +
        acs_url;
      const client = this.$root.getAjaxFetchClient();
      let res = await client.getRequest(API_URL);
      let validUrl = null;
      outer_loop: for (let key in res?.result) {
        validUrl = key;
        break outer_loop;
      }
      return res?.result?.[validUrl]?.[acs_url] ?? [];
    },
    async fetchAccessLevel(acs_url) {
      let API_URL =
        this.getAPIServerURL +
        "/api/acsservice/info/access-levels/?acs_url=" +
        acs_url;
      const client = this.$root.getAjaxFetchClient();
      let res = await client.getRequest(API_URL);
      let validUrl = null;
      outer_loop: for (let key in res?.result) {
        validUrl = key;
        break outer_loop;
      }
      return res?.result?.[validUrl]?.[acs_url] ?? [];
    },
    async fetchLiftAccessLevel(acs_url) {
      var API_URL =
        this.getAPIServerURL +
        "/api/acsservice/info/lift-access-levels/?acs_url=" +
        acs_url;
      const client = this.$root.getAjaxFetchClient();
      let res = await client.getRequest(API_URL);
      let validUrl = null;
      outer_loop: for (var key in res?.result) {
        validUrl = key;
        break outer_loop;
      }
      return res?.result?.[validUrl]?.[acs_url] ?? [];
    },

    changeLabelLiftAccess() {
      let result = null;
      let id = null;
      let value = null;
      for (let j in this.options_lift_access_levels) {
        if (
          this.getMasterAccessMetadata?.lift_access_level ===
          this.options_lift_access_levels[j].lift_access_level
        ) {
          id = this.options_lift_access_levels[j].lift_access_level;
          value = this.options_lift_access_levels[j].description;
          result = true;
        }
      }
      this.lift_access_level = result
        ? `${id} - ${value}`
        : `${this.company.access_metadata.lift_access_level.lift_access_level} - ${this.company.access_metadata.lift_access_level.description}`;
    },

    timer() {
      let that = this;
      that.intervalid = setInterval(() => {
        that.countdownCounter -= 1;
      }, 1000);
      that.redirectTimeout = setTimeout(async () => {
        that.$bvModal
          .msgBoxOk(
            "Your session is expired due to inactivity. If you need further assistance, please check with receptionist or guard.",
            {
              centered: true
            }
          )
          .then((value) => {
            if (value) {
              that.$store.dispatch("kiosk2/resetAll");
              that.$router.push({ name: "kiosk2" });
            }
          });
      }, 180000);
      this.timeOut();
    },
    timeOut() {
      let that = this;
      that.redirectTimeout = setTimeout(async () => {
        that.$bvModal
          .msgBoxOk(
            "Your session will expire in 30 second due to inactivity, please click OK button is you want to continue",
            {
              centered: true
            }
          )
          .then((value) => {
            if (value) {
              that.$store.dispatch("kiosk2/resetAll");
              that.$router.push({
                name: "visit-detail",
                params: { id: this.visitId }
              });
            }
          });
      }, 150000);
    },

    accessTypes(type) {
      let result = null;
      switch (this.$route.query.parents_url) {
        case "kiosk2":
          result =
            (type == "card"
              ? this.getPreference?.additional_metadata
                  ?.vms_credential_kiosk_physical_card
              : type == "dynamic qr"
              ? this.getPreference?.additional_metadata
                  ?.vms_credential_kiosk_dynamic_qr
              : type == "static qr"
              ? this.getPreference?.additional_metadata
                  ?.vms_credential_kiosk_static_qr
              : type == "face"
              ? this.getPreference?.additional_metadata
                  ?.vms_credential_kiosk_face_recognition
              : type == "car_plate_no"
              ? this.getPreference?.additional_metadata
                  ?.vms_credential_kiosk_license_plate_recognition
              : type == "fingerprint"
              ? this.getPreference?.additional_metadata
                  ?.vms_credential_kiosk_fingerprint
              : this.getPreference?.additional_metadata
                  ?.vms_credential_kiosk_lift_access_physical_card ||
                this.getPreference?.additional_metadata
                  ?.vms_credential_kiosk_lift_access_static_qr ||
                this.getPreference?.additional_metadata
                  ?.vms_credential_kiosk_lift_access_dynamic_qr ||
                this.getPreference?.additional_metadata
                  ?.vms_credential_kiosk_lift_access_face_recognition) ?? false;
          break;
        default:
          result =
            (type == "card"
              ? this.getPreference?.additional_metadata
                  ?.vms_credential_operator_physical_card
              : type == "dynamic qr"
              ? this.getPreference?.additional_metadata
                  ?.vms_credential_operator_dynamic_qr
              : type == "static qr"
              ? this.getPreference?.additional_metadata
                  ?.vms_credential_operator_static_qr
              : type == "face"
              ? this.getPreference?.additional_metadata
                  ?.vms_credential_operator_face_recognition
              : type == "car_plate_no"
              ? this.getPreference?.additional_metadata
                  ?.vms_credential_operator_license_plate_recognition
              : type == "fingerprint"
              ? this.getPreference?.additional_metadata
                  ?.vms_credential_operator_fingerprint
              : this.getPreference?.additional_metadata
                  ?.vms_credential_operator_lift_access_physical_card ||
                this.getPreference?.additional_metadata
                  ?.vms_credential_operator_lift_access_static_qr ||
                this.getPreference?.additional_metadata
                  ?.vms_credential_operator_lift_access_dynamic_qr ||
                this.getPreference?.additional_metadata
                  ?.vms_credential_operator_lift_access_face_recognition) ??
            false;
      }
      return result;
    },
    liftAccessLevel() {
      let result = null;
      switch (this.$route.query.parents_url) {
        case "kiosk2":
          result =
            (this.getPreference?.additional_metadata
              ?.vms_credential_kiosk_lift_access_physical_card ||
              this.getPreference?.additional_metadata
                ?.vms_credential_kiosk_lift_access_dynamic_qr ||
              this.getPreference?.additional_metadata
                ?.vms_credential_kiosk_lift_access_static_qr ||
              this.getPreference?.additional_metadata
                ?.vms_credential_kiosk_lift_access_face_recognition) ??
            false;
          break;
        default:
          result =
            (this.getPreference?.additional_metadata
              ?.vms_credential_operator_lift_access_physical_card ||
              this.getPreference?.additional_metadata
                ?.vms_credential_operator_lift_access_dynamic_qr ||
              this.getPreference?.additional_metadata
                ?.vms_credential_operator_lift_access_static_qr ||
              this.getPreference?.additional_metadata
                ?.vms_credential_operator_lift_access_face_recognition) ??
            false;
      }
      return result;
    },
    disabled(type) {
      let status =
        (this.visit_data?.status === "EXPIRED" ||
          this.visit_data?.status === "CANCELLED" ||
          this.visit_data?.status === "COMPLETED") ??
        false;

      let result = null;
      console.log("check_in_time", this.visit_data.additional_metadata);
      switch (type) {
        case "generate access":
          result =
            this.isEditAccessGroups == false
              ? this.visit_data?.additional_metadata?.check_in_time || status
              : true;
          break;
        case "revoke access":
          result =
            this.isEditAccessGroups == false
              ? !this.visit_data?.additional_metadata?.check_in_time || status
              : true;
          break;
        default:
          result = status;
      }
      return result;
    },
    //checking access
    containCardAccess(access) {
      try {
        if (access["access_types"].includes("card")) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    },
    containQrAccess(access) {
      try {
        if (access["access_types"].includes("qr")) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    },
    containFaceAccess(access) {
      try {
        if (access["access_types"].includes("face")) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    },
    customLabel({ description, lift_access_level }) {
      return `${lift_access_level} - ${description}`;
    },
    accessQrContentFormatter(qr_string) {
      let myArray = qr_string.split(",")[1];
      return myArray;
    },
    handleAccessGroups(type) {
      let $this = this;
      let access_groups = [];
      if (type == "company") {
        if (this.company.access_metadata.access_groups.length != 0) {
          for (let key3 in this.company.access_metadata.access_groups) {
            access_groups.push(
              this.company.access_metadata.access_groups[key3].access_group
            );
          }
        } else {
          if ($this.getPreference.additional_metadata != undefined) {
            for (let key4 in this.getPreference.additional_metadata
              .vms_preferences_access_groups) {
              access_groups.push(
                this.getPreference.additional_metadata
                  .vms_preferences_access_groups[key4].access_group
              );
            }
          }
        }
      } else if (type == "setting") {
        if ($this.getPreference.additional_metadata != undefined) {
          for (let key4 in this.getPreference.additional_metadata
            .vms_preferences_access_groups) {
            access_groups.push(
              this.getPreference.additional_metadata
                .vms_preferences_access_groups[key4].access_group
            );
          }
        }
      } else {
        if (this.getMasterAccessMetadata.access_groups.length != 0) {
          for (let key5 in this.getMasterAccessMetadata.access_groups) {
            access_groups.push(
              this.getMasterAccessMetadata.access_groups[key5]
            );
          }
        }
      }
      return access_groups.toString();
    },

    // formatter
    async handleQRPreview() {
      this.$refs["previewQR"].show();
    },
    async handlePdfPreview(item) {
      this.isFace = item;
      this.$refs["previewPdf"].show();
    },
    async handleBadgePdfPreview() {
      this.$refs["previewVisitBadgePdf"].show();
    },

    showEmailChecklistModal() {
      this.$bvModal.show("emailChecklistModal");
    },
    async triggerSendNotification(
      notification_type,
      access_types,
      qr_code_type
    ) {
      let API_URL =
        this.getAPIServerURL + "/api/visitorservice/visit/notification/";

      let data = {
        visit_id: this.visit_data.id,
        visitor_ids: [this.getMasterVisitor.id],
        notification_type: notification_type,
        notification_methods: ["email"]
      };

      if (notification_type === "visit_access" && access_types == null) {
        // todo: add function to get all access
        data = {
          ...data,
          access_types: ["card", "qr", "face", "car_plate_no", "fingerprint"],
          qr_code_type: "dynamic"
        };
      } else if (access_types != null) {
        if (access_types) {
          data = {
            ...data,
            access_types: access_types,
            qr_code_type: qr_code_type
          };
        }

        if (access_types.includes("qr")) {
          data = {
            ...data,
            qr_code_type: qr_code_type
          };
        }
      }

      const client = this.$root.getAjaxFetchClient();

      let res = await client.postRequest(API_URL, data);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (let key in result) {
          if (result[key] !== undefined) {
            validUrl = key;
            break outer_loop;
          }
        }
      }

      if (result[validUrl]) {
        let visit_data = await this.fetchVisit(this.visitId);
        this.visit_data = visit_data;
        console.log("visit_data", visit_data);

        await this.$store.dispatch("session/addGlobalAlertMessage", {
          message_text: "Notification sent!",
          message_type: "success"
        });
      }
    },
    closeModalEmail() {
      this.$refs["showEmail"].hide();
    },
    containCarPlateNumberAccess(access) {
      let carPlateNo = access?.car_plate_no?.match(
        "^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$"
      );
      console.log("carPlateNo", carPlateNo);
      try {
        if (
          access["access_types"].includes("car_plate_no") &&
          carPlateNo != null
        ) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    },
    containFingerprintAccess(access) {
      try {
        if (access["access_types"].includes("fingerprint")) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    },
    close() {
      this.formErrorMessage = null;
      this.form.access_card_no = null;
    },

    async scCompressBase64Image(base64Image) {
      console.log("base64Image", base64Image);

      const buffer = Buffer.from(
        base64Image.substring(base64Image.indexOf(",") + 1)
      );
      console.log("Byte length: " + buffer.length);
      console.log("MB: " + buffer.length / 1e6);

      let base64Response = await fetch(base64Image);

      let blob = await base64Response.blob();

      console.log("blob", blob);

      let compressedBlob = await imageConversion.compressAccurately(blob, {
        size: 100, //The compressed image size is 100kb
        accuracy: 0.5, //the accuracy of image compression size,range 0.8-0.99,default 0.95;
        //this means if the picture size is set to 1000Kb and the
        //accuracy is 0.9, the image with the compression result
        //of 900Kb-1100Kb is considered acceptable;
        type: "image/jpeg",
        width: 250,
        height: 350,
        orientation: 2,
        scale: 0.8
      });

      let compressedBase64String = await convertBlobToBase64(compressedBlob);
      const buffer2 = Buffer.from(
        compressedBase64String.substring(
          compressedBase64String.indexOf(",") + 1
        )
      );
      console.log("Byte length: " + buffer2.length);
      console.log("MB: " + buffer2.length / 1e6);

      return compressedBase64String;
    },
    showImage: function (img) {
      let $this = this;
      return img ? `data:image/jpeg;base64,${img}` : $this.defaultImage;
    },
    showEmptyFaceModal() {
      this.$bvModal
        .msgBoxConfirm(
          `Visitor ${this.getMasterVisitor.name} do not have face image. Do you want to add a face image now?`,
          {
            title: "Face image required",
            size: "sm",
            buttonSize: "sm",
            okTitle: "Yes",
            cancelTitle: "No",
            hideHeaderClose: false,
            centered: true
          }
        )
        .then((value) => {
          if (value) {
            console.log(value);
            this.getWebCamDeviceList();
            this.$bvModal.show("form-add-visitor-image");
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    // upload photo or capture photo
    onFileChange: function (item, e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(item, files[0]);
    },
    createImage: function (item, file) {
      let $this = this;
      let reader = new FileReader();

      reader.onload = async (e) => {
        let base64Image2 = await this.scCompressBase64Image(e.target.result);

        item.face_image = base64Image2.substring(base64Image2.indexOf(",") + 1);

        $this.uploadedPhoto = item.face_image;
        $this.showUploadPhoto = true;
      };
      reader.readAsDataURL(file);
    },
    switchCamera() {
      if (this.cameraType == "user") {
        this.cameraType = "environment";
        console.log("this.cameraType1", this.cameraType);

        this.removeCameraStream();
      } else if (this.cameraType == "environment") {
        this.cameraType = "user";
        console.log("this.cameraType2", this.cameraType);

        this.removeCameraStream();
      } else if (this.cameraType == null) {
        this.cameraType = "user";
        console.log("this.cameraType3", this.cameraType);
      }
    },
    uploadPhoto: function () {
      let $this = this;
      $this.countTrigger = 0;
      $this.capturedPhoto = null;
      $this.showTakePhoto = false;

      $this.stopCamera();
      $this.removeCameraStream();
      $this.removePhotoView();

      if (document.getElementById("input-face_image").value != "") {
        document.getElementById("input-face_image").value = "";
      }

      document.getElementById("input-face_image").click();
    },
    reuploadPhoto: function () {
      let $this = this;
      $this.showUploadPhoto = false;
      $this.uploadedPhoto = null;
    },
    getWebCamDeviceList: function () {
      this.switchCamera();
      let $this = this;

      const constraints = (window.constraints = {
        audio: false,
        video: {
          width: 350,
          height: 350,
          facingMode: this.cameraType
        }
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          let cam_stream;
          cam_stream = stream;
          let live_video;

          live_video = document.createElement("video");
          live_video.srcObject = stream;
          live_video.setAttribute("id", "camera_stream");
          live_video.setAttribute("width", 350);
          live_video.setAttribute("height", 350);
          live_video.setAttribute("autoplay", true);
          live_video.setAttribute("muted", "");
          live_video.setAttribute("playsinline", "");
          $this.$refs.cameraView.appendChild(live_video);

          $this.formAdd.face_image = null;

          $this.capturedPhoto = null;

          $this.showTakePhoto = true;
          $this.showUploadPhoto = false;

          $this.countTakePhoto++;
        })
        .catch((error) => {
          console.log(error);
          alert(
            "Your connection is not secured. Please contact your system administrator."
          );
          this.$bvModal.hide("form-add-visitor-image");
          this.countTakePhoto = 0;
        });
    },
    takePhoto: async function () {
      let $this = this;
      let canvas = document.createElement("canvas");
      let video = document.getElementById("camera_stream");
      canvas.width = video.videoWidth * 0.7;
      canvas.height = video.videoHeight * 0.7;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height);

      let base64Image = canvas.toDataURL("image/jpeg");

      let base64Image2 = await this.scCompressBase64Image(base64Image);

      let base64str = base64Image2.substring(base64Image2.indexOf(",") + 1);

      $this.capturedPhoto = base64str;
      $this.formAdd.face_image = $this.capturedPhoto;

      //$this.$refs.photoView.appendChild(canvas);
    },
    retakePhoto: function () {
      let $this = this;
      $this.removePhotoView();
      $this.capturedPhoto = null;
    },
    stopCamera: function () {
      let $this = this;
      let tracks = null;
      if ($this.$refs?.cameraView) {
        if ($this.$refs?.cameraView?.lastChild != null) {
          if ($this.formAdd.face_image != null) {
            this.onSubmitUpdateForm();
          }
          tracks = $this.$refs?.cameraView?.lastChild?.srcObject.getTracks();
          tracks.forEach((track) => {
            track.stop();
          });
        }
      }
      $this.countTrigger = 0;
    },
    removeCameraStream: function () {
      let $this = this;

      if ($this.$refs.cameraView != null) {
        while ($this.$refs.cameraView.hasChildNodes()) {
          $this.$refs.cameraView.removeChild($this.$refs.cameraView.lastChild);
        }
      }
    },
    removePhotoView: function () {
      let $this = this;

      if ($this.$refs.photoView != null) {
        while ($this.$refs.photoView.hasChildNodes()) {
          $this.$refs.photoView.removeChild($this.$refs.photoView.lastChild);
        }
      }
    },

    getPartial(input) {
      let length = input.length;
      let input2 = input.substring(0, length - 4).split("");
      let input3 = input.substring(length - 4);

      let output = [];
      input2.forEach((letter) => {
        letter = "*";
        output.push(letter);
      });

      let answer = output.join("") + input3;
      return answer;
    },
    getCustomId(data) {
      let contact_number = data.contact_number;
      let length1 = contact_number.length;
      contact_number = contact_number.substring(length1 - 4);

      //let email = data.email.substring(4, 0);

      let custom_id = null;

      if (data.passport_number != null) {
        let length2 = data.passport_number.length;
        let passport_number = data.passport_number.substring(length2 - 4);
        custom_id = contact_number + passport_number;

        return custom_id;
      } else {
        let length3 = data.nric_number.length;
        let nric_number = data.nric_number.substring(length3 - 4);
        custom_id = contact_number + nric_number;

        return custom_id;
      }
    },
    getAccessGroups(type) {
      let array = [];
      if (type == "accessMetadata") {
        for (let k in this.getMasterAccessMetadata?.access_groups) {
          for (let l in this.options_access_groups) {
            if (
              this.getMasterAccessMetadata?.access_groups[k] ==
              this.options_access_groups[l].access_group
            ) {
              array.push({
                access_group: this.getMasterAccessMetadata?.access_groups[k],
                description: this.options_access_groups[l].description
              });
            }
          }
        }
      } else {
        for (let k in this.company?.access_metadata?.access_groups) {
          array.push(
            this.company?.access_metadata?.access_groups[k].access_group
          );
        }
      }
      return array;
    },
    getAccessLevel() {
      let access_level = null;
      access_level =
        this.companysList?.access_metadata?.access_level?.access_level ??
        this.getPreference?.additional_metadata?.vms_preferences_access_level
          ?.access_level;
      return access_level;
    },
    getLiftAccessLevel() {
      let object = [];
      for (let key9 in this.options_lift_access_levels) {
        if (
          this.getMasterAccessMetadata?.lift_access_level ==
          this.options_lift_access_levels[key9].lift_access_level
        ) {
          object = {
            lift_access_level: this.getMasterAccessMetadata?.lift_access_level,
            description: this.options_lift_access_levels[key9].description
          };
        }
      }
      return object;
    },

    // start invitation code
    async invitationCode() {
      if (this.invite_code == null) {
        let start_datetime = moment(this.visit_data.visit_start_date);
        let end_datetime = moment(this.visit_data.visit_end_date);

        let data = {
          visit_id: this.visit_data.id,
          start_datetime: start_datetime.toISOString(),
          end_datetime: end_datetime.toISOString(),
          status: "ACCEPTED"
        };

        try {
          let res = await this.createInvitationCode(data);

          if (Object.prototype.hasOwnProperty.call(res, "invite_code")) {
            this.invite_code = res.invite_code;
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    async createInvitationCode(data) {
      let API_URL = this.getAPIServerURL + "/api/visitorservice/invite/";
      const client = this.$root.getAjaxFetchClient();
      let res = await client.postRequest(API_URL, data);
      let validUrl = null;
      outer_loop: for (let key in res?.result) {
        validUrl = key;
        break outer_loop;
      }
      return res?.result?.[validUrl];
    },
    // update visitor
    onSubmitUpdateForm: function () {
      //e.preventDefault();
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/visitor/?visitor_id=" +
        $this.getMasterVisitor.id;
      const client = $this.$root.getAjaxFetchClient();

      let updateData = {
        ...$this.getMasterVisitor,
        ...this.formAdd
      };

      client
        .putRequest(API_URL, updateData)
        .then(async (data) => {
          // update visitor to store
          let updateVisitor;
          outer_loop: for (let key in data.result) {
            if (data.result[key] == null) {
              updateVisitor = data.result[key];
              if (updateVisitor == null) {
                let visitors = await this.fetchVisitors(this.visit_data);
                console.log("visitors", visitors);
                this.visitors = visitors;
              } else {
                if (data.detail != undefined) {
                  console.log("error data.detail", data.detail);
                }
              }
              break outer_loop;
            }
          }
        })
        .catch((err) => {
          console.log("onSubmitUpdateForm Error", err);
        });
    },

    // update visit details
    showUpdateVisitDetailsForm() {
      this.isEditAccessGroups = true;
      this.isEditLiftAccessLevel = true;
      let data = {
        access_groups: this.getMasterAccessMetadata?.access_groups
          ? this.getAccessGroups("accessMetadata")
          : this.company?.access_metadata?.access_groups
          ? this.company?.access_metadata?.access_groups
          : [],
        access_level: this.getMasterAccessMetadata?.card_access_level
          ? this.getMasterAccessMetadata?.card_access_level
          : this.company?.access_metadata?.access_level
          ? this.company?.access_metadata?.access_level.access_level
          : 0,
        lift_access_level: this.getMasterAccessMetadata?.lift_access_level
          ? this.getLiftAccessLevel()
          : this.company?.access_metadata?.lift_access_level
          ? this.company?.access_metadata?.lift_access_level
          : 0
      };

      this.formUpdate.access_metadata.access_groups = data.access_groups;
      this.formUpdate.access_metadata.access_level = data.access_level;
      this.formUpdate.access_metadata.lift_access_level =
        data.lift_access_level;
    },
    onSubmitUpdateVisitDetailsForm() {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to update Visit Details?", {
          centered: true
        })
        .then((value) => {
          if (value) {
            this.onSubmitUpdateForm();
            this.updateVisitDetailsForm();
          } else {
            this.formAdd.face_image = null;
            this.isEditAccessGroups = false;
            this.isEditLiftAccessLevel = false;
          }
        })
        .catch((err) => {
          // An error occurred
          console.log("onSubmitUpdateVisitDetailForm error", err);
          this.isEditAccessGroups = false;
          this.isEditLiftAccessLevel = false;
        });
    },
    updateVisitDetailsForm() {
      let API_URL = null;
      let access_groups = [];
      for (let m in this.formUpdate?.access_metadata?.access_groups) {
        access_groups.push(
          this.formUpdate.access_metadata.access_groups[m].access_group
        );
      }
      if (this.getMasterAccessMetadata?.access_types?.length > 0) {
        let query = {
          access_card_no: this.getMasterAccessMetadata?.access_card_no ?? null,
          access_groups: access_groups,
          access_level:
            this.getMasterAccessMetadata?.card_access_level ??
            this.formUpdate.access_metadata.access_level,
          access_types: this.getMasterAccessMetadata?.access_types ?? [],
          as_format: "base64",
          car_plate_no: this.getMasterAccessMetadata?.car_plate_no ?? null,
          face_image:
            this.formAdd?.face_image ?? this.getMasterVisitor?.face_image,
          lift_access_level:
            this.formUpdate.access_metadata.lift_access_level.lift_access_level,
          qr_code_type: this.getMasterAccessMetadata?.qr_code_type ?? null,
          visit_end_date: this.visit_data.visit_end_date,
          visit_end_time: this.visit_data.visit_end_time,
          visit_id: this.visit_data.id,
          visitor_id: this.getMasterVisitor?.id,
          previous_data: {}
        };
        console.log("qeury", query);
        API_URL =
          this.getAPIServerURL +
          "/api/visitorservice/visit/access/?visit_id=" +
          this.visit_data?.id;

        const client = this.$root.getAjaxFetchClient();
        client
          .putRequest(API_URL, query)
          .then(async () => {
            this.isEditAccessGroups = false;
            this.isEditLiftAccessLevel = false;
            this.visit_data = await this.fetchVisit(this.visit_data.id);
            this.changeLabelLiftAccess();
            await this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: `Update visit details successfully. `,
              message_type: "success"
            });
            //location.reload();
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else {
        let array = [
          {
            end_datetime: this.getMasterAccessMetadata?.end_datetime ?? null,
            start_datetime:
              this.getMasterAccessMetadata?.start_datetime ?? null,
            access_card_no:
              this.getMasterAccessMetadata?.access_card_no ?? null,
            qr_string: this.getMasterAccessMetadata?.qr_string ?? null,
            access_types: this.getMasterAccessMetadata?.access_types ?? [],
            car_plate_no: this.getMasterAccessMetadata?.car_plate_no ?? null,
            qr_code_type: this.getMasterAccessMetadata?.qr_code_type ?? null,
            card_access_level:
              this.getMasterAccessMetadata?.card_access_level ??
              this.formUpdate.access_metadata.access_level,
            access_groups: access_groups,
            lift_access_level:
              this.formUpdate.access_metadata.lift_access_level
                .lift_access_level
          }
        ];
        let visitors = {};
        visitors[this.getMasterVisitor.id] = array;
        let access_metadata = {
          visitors: visitors
        };
        this.visit_data.access_metadata = access_metadata;
        console.log("visit data", this.visit_data);

        API_URL =
          this.getAPIServerURL +
          "/api/visitorservice/visit/?visit_id=" +
          this.visit_data?.id;

        const client = this.$root.getAjaxFetchClient();
        client
          .putRequest(API_URL, this.visit_data)
          .then(async () => {
            this.isEditAccessGroups = false;
            this.isEditLiftAccessLevel = false;
            this.visit_data = await this.fetchVisit(this.visit_data.id);
            this.changeLabelLiftAccess();
            await this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: `Update visit details successfully. `,
              message_type: "success"
            });
            //location.reload();
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },

    // generate access
    showAddAccessForm() {
      let credentialIssuance =
        this.getPreference.additional_metadata
          .vms_preferences_credential_physical_card ||
        this.getPreference.additional_metadata
          .vms_preferences_credential_dynamic_qr ||
        this.getPreference.additional_metadata
          .vms_preferences_credential_static_qr ||
        this.getPreference.additional_metadata
          .vms_preferences_credential_face_recognition
          ? true
          : false;

      credentialIssuance
        ? (this.formAddAccess(), this.$refs["addAccessForm"].show())
        : this.$bvModal.msgBoxOk(
            `No set for credential issues yet. Please set the credential issue in the setting pages before generate access for visitor.`,
            {
              centered: true
            }
          );
    },
    formAddAccess() {
      this.form.access_types =
        this.$route.query.parents_url == "kiosk2"
          ? this.$store.getters["vmsPreferences2Module/kioskPresetCredential"]
          : this.$store.getters[
              "vmsPreferences2Module/operatorPresetCredential"
            ];
      this.form.qr_code_type =
        this.getPreference.additional_metadata
          .vms_credential_operator_dynamic_qr ||
        this.getPreference.additional_metadata.vms_credential_kiosk_dynamic_qr
          ? "dynamic"
          : this.getPreference.additional_metadata
              .vms_credential_operator_static_qr ||
            this.getPreference.additional_metadata
              .vms_credential_kiosk_static_qr
          ? "static"
          : null;

      this.form.access_groups = this.getMasterAccessMetadata?.access_groups
        ? this.getMasterAccessMetadata?.access_groups
        : this.company?.access_metadata?.access_groups
        ? this.getAccessGroups("company")
        : [];

      this.form.access_level = this.getMasterAccessMetadata?.access_level
        ? this.getMasterAccessMetadata?.access_level
        : this.company?.access_metadata?.access_level
        ? this.company?.access_metadata?.access_level.access_level
        : 0;

      this.form.lift_access_level =
        this.getMasterAccessMetadata?.lift_access_level >= 0
          ? this.getMasterAccessMetadata?.lift_access_level
          : this.company?.access_metadata?.lift_access_level
          ? this.company?.access_metadata?.lift_access_level.lift_access_level
          : 0;

      console.log("this.form", this.form);
    },
    // add access : step 1
    onSubmitAddAccessForm: function (e) {
      e.preventDefault();
      let result_face = true;
      let result_car_plate_no = true;
      let result_fingerprint = true;

      if (this.form.access_types.includes("face")) {
        if (!this.getMasterVisitor?.face_image) {
          result_face = false;
          this.showEmptyFaceModal();
          return result_face;
        }
      }

      if (this.form.access_types.includes("car_plate_no")) {
        let car_plate_no =
          this.$route?.query?.parents_url == "kiosk2"
            ? this.getPreference?.additional_metadata
                ?.vms_credential_kiosk_license_plate_recognition
            : this.getPreference?.additional_metadata
                ?.vms_credential_operator_license_plate_recognition;

        result_car_plate_no =
          car_plate_no && !this.getMasterVisitor?.profile?.car_plate_no
            ? false
            : true;
      }

      if (this.form.access_types.includes("fingerprint")) {
        let fingerprint =
          this.$route?.query?.parents_url == "kiosk2"
            ? this.getPreference?.additional_metadata
                ?.vms_credential_kiosk_fingerprint
            : this.getPreference?.additional_metadata
                ?.vms_credential_operator_fingerprint;

        result_fingerprint =
          fingerprint &&
          (!this.getMasterVisitor?.profile?.fingerprint ||
            (!this.getMasterVisitor?.profile?.fingerprint?.template1 &&
              !this.getMasterVisitor?.profile?.fingerprint?.template2))
            ? false
            : true;
      }

      if (result_car_plate_no == false && result_fingerprint) {
        this.$route?.query?.parents_url == "kiosk2"
          ? this.$bvModal.show("form-error-message-car-plate-no-kiosk")
          : this.$bvModal.show("form-error-message-car-plate-no-operator");
        return result_car_plate_no;
      }

      if (result_fingerprint == false && result_car_plate_no) {
        this.$route?.query?.parents_url == "kiosk2"
          ? this.$bvModal.show("form-error-message-fingerprint-kiosk")
          : this.$bvModal.show("form-error-message-fingerprint-operator");
        return result_fingerprint;
      }

      if (result_car_plate_no == false && result_fingerprint == false) {
        this.$route?.query?.parents_url == "kiosk2"
          ? this.$bvModal.show("form-error-message-both-kiosk")
          : this.$bvModal.show("form-error-message-both-operator");
        return false;
      }

      this.submitAddAccess();
    },
    // add access : step 2
    submitAddAccess() {
      let $this = this;
      let query = {
        visit_id: this.visit_data?.id,
        visitor_id: this.getMasterVisitor?.id,
        face_image: this.getMasterVisitor?.face_image,
        car_plate_no: this.getMasterVisitor?.profile?.car_plate_no ?? "-",
        access_groups: this.form.access_groups,
        access_level: this.form.access_level,
        lift_access_level: this.form.lift_access_level,
        qr_code_type: this.form.qr_code_type,
        access_card_no: this.form.access_card_no,
        access_types: this.form.access_types,
        as_format: "base64",
        fingerprint_template_1:
          this.getMasterVisitor?.profile?.fingerprint?.template1 ?? null,
        fingerprint_template_2:
          this.getMasterVisitor?.profile?.fingerprint?.template2 ?? null
      };
      console.log("post access data", query);
      let API_URL = $this.getAPIServerURL + "/api/visitorservice/visit/access/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, query)
        .then(async (data) => {
          if (data.detail != undefined) {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: data.detail,
              message_type: "danger"
            });

            $this.formErrorMessage = data.detail;
          } else {
            let result = data.result;
            let validUrl = "";
            if (result !== undefined) {
              outer_loop: for (let key in result) {
                if (result[key] !== undefined) {
                  validUrl = key;
                  break outer_loop;
                }
              }
            }

            if (result[validUrl] != null) {
              console.log("add_access_success", result[validUrl]);
              await this.handleAddAccessSuccess();
              //  this.getPreference?.additional_metadata
              //  ?.vms_check_in_as_soon_as_credential_is_issued
              // ? this.checkIn(this.visit_data)
              // : await this.handleAddAccessSuccess();
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // add access : step 3
    checkIn(visit_data) {
      try {
        let $this = this;
        let API_URL =
          $this.getAPIServerURL +
          "/api/visitorservice/visit/check-in/?visit_id=" +
          visit_data.id;
        const client = $this.$root.getAjaxFetchClient();
        client
          .postRequest(API_URL)
          .then(async (data) => {
            console.log("checkIn_success", data);
            let validUrl = null;
            outer_loop: for (var key in data?.result) {
              validUrl = key;
              break outer_loop;
            }
            if (data?.result?.[validUrl] == null) {
              this.handleAddAccessSuccess();
            }
            //this.submitAddAccess();
          })
          .catch((error) => {
            console.log("error", error);
          });
      } catch (e) {
        console.log("e");
        console.log(e);
      }
    },
    // add access : step 4
    async handleAddAccessSuccess() {
      try {
        this.visit_data = await this.fetchVisit(this.visitId);
        console.log("visit_data_success", this.visit_data);
        this.access =
          this.visit_data?.access_metadata?.visitors?.[
            this.getMasterVisitor.id
          ];
        for (let i = 0; i < this.access.length; i++) {
          this.access[i]["face_image"] = this.getMasterVisitor?.face_image
            ? this.getMasterVisitor?.face_image
            : null;
        }

        let message = `Success add access to visitor`;
        await this.$store.dispatch("session/addGlobalAlertMessage", {
          message_text: message,
          message_type: "success"
        });

        this.isEditAccessGroups = false;
        this.isEditLiftAccessLevel = false;
        this.$refs.addAccessForm.hide();
        this.resetAddAccessForm();
      } catch (e) {
        console.log("e");
        console.log(e);
      }
    },
    // add access : step 5
    resetAddAccessForm() {
      this.form = {
        access_types: [],
        access_groups: [],
        access_level: null,
        access_card_no: null,
        selected_acs_url: null
      };

      if (this.acsUrls.length > 0) {
        this.form.selected_acs_url = this.acsUrls[0];
      }
    },

    // delete access
    triggerDeleteAllAccess() {
      let that = this;
      this.$bvModal
        .msgBoxConfirm(
          `Confirm revoke all access from visitor ${this.getMasterVisitor.name}?`,
          {
            centered: true
          }
        )
        .then((value) => {
          if (value) {
            that.handleDeleteAllAccess(
              this.getMasterVisitor,
              this.getMasterAccessMetadata[0]
            );
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    async handleDeleteAllAccess(user, access) {
      let data = {
        visit_id: this.visitId,
        visitor_id: user.id,
        previous_data: {
          //access_card_no: this.getMasterAccessMetadata.access_card_no
        }
      };
      let API_URL =
        this.getAPIServerURL + "/api/visitorservice/visit/access/all/";
      const client = this.$root.getAjaxFetchClient();
      let res = await client.deleteRequest(API_URL, data);
      console.log(res);
      if (res.detail !== undefined) {
        await this.$store.dispatch("session/addGlobalAlertMessage", {
          message_text: res.detail,
          message_type: "danger"
        });
      } else {
        let result = res.result;
        let validUrl = "";
        if (result !== undefined) {
          outer_loop: for (let key in result) {
            if (result[key] !== undefined) {
              validUrl = key;
              break outer_loop;
            }
          }
        }

        if (result[validUrl]) {
          console.log("true", result[validUrl]);
          let visit_data = await this.fetchVisit(this.visitId);
          this.visit_data = visit_data;

          console.log("this.visit_data", this.visit_data);

          this.revokeVisitRegistration(visit_data, user);
        }
      }
    },
    //revoke
    revokeVisitRegistration(visit_data, user) {
      let $this = this;
      for (let key in Object.keys(visit_data.additional_metadata)) {
        if (
          Object.keys(visit_data.additional_metadata)[key] == "notifications"
        ) {
          delete visit_data.additional_metadata.notifications[
            this.getMasterVisitor.id
          ].visit_access;
        }
        console.log(
          "this.visit_data.additional_metadata",
          visit_data.additional_metadata
        );
      }
      visit_data.status = "CANCELLED";
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/visit/?visit_id=" +
        visit_data.id;
      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, visit_data)
        .then(async () => {
          await this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: `Success revoke all access from visitor ${user.name}`,
            message_type: "success"
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // start print & download
    downloadPdf() {
      let doc = new jsPDF("p", "pt", "a4");
      /* let margins = {
        top: 80,
        bottom: 60,
        left: 40,
        width: 522
      }; */
      let inviteCode = this.invite_code;
      let visit_code = this.visit_data.visit_code;
      let nameVisitor = `${this.getMasterVisitor.name}`;

      if (this.$refs.previewReceiptHtml != undefined) {
        doc.html(this.$refs.previewReceiptHtml, {
          callback: function (doc) {
            //doc.save("output.pdf");
            doc.save(
              "Visitation code " + visit_code + " " + nameVisitor + ".pdf"
            );
          },
          x: 60,
          y: 30
        });
      } else {
        doc.html(this.$refs.previewQRHtml, {
          callback: function (doc) {
            //doc.save("output.pdf");
            doc.save(
              "Visitation code " + visit_code + " " + nameVisitor + ".pdf"
            );
          },
          x: 70,
          y: 170
        });
      }
    },
    printPdf() {
      let doc = new jsPDF("p", "pt", "a4");
      let margins = {
        top: 80,
        bottom: 60,
        left: 40,
        width: 522
      };

      let inviteCode = this.invite_code;
      let nameVisitor = `${this.getMasterVisitor.name}`;

      if (this.$refs.previewReceiptHtml != undefined) {
        doc.html(this.$refs.previewReceiptHtml, {
          callback: function (doc) {
            doc.autoPrint();
            doc.output("dataurlnewwindow");
          },
          x: 60,
          y: 30
        });
      } else {
        doc.html(this.$refs.previewQRHtml, {
          callback: function (doc) {
            doc.autoPrint();
            doc.output("dataurlnewwindow");
          },
          x: 70,
          y: 170
        });
      }
    }
  },

  beforeDestroy() {
    clearInterval(this.intervalid);
    clearTimeout(this.redirectTimeout);
  }
};
</script>
<style lang="scss">
@import "./App.scss";
@import "./../Registration/App.scss";

.camera-button {
  background-color: #fec20c;
  border: none;
  padding: 0px 6px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
